import { useEffect, useState } from "react";

export function useFirstVisit() {
  const [firstVisit, setFirstVisit] = useState(false);

  useEffect(() => {
    // Check if the page has been visited before
    const visited = localStorage.getItem("visited");
    // If not, set the flag to true and store it in localStorage
    if (!visited) {
      setFirstVisit(true);
      localStorage.setItem("visited", "true");
    }
  }, []);

  return firstVisit;
}
