import React from "react";
import ArticleCard from "../../cards/ArticleCard";

export default function Others({ blogs, style }) {
  return (
    <div className="my-28">
      <div className="flex flex-col items-center px-4 lg:mt-4">
        <p className="flex flex-col items-center text-center py-[2px] px-4 font-black text-xl md:text-2xl lg:text-3xl">
          Others
          <svg
            height="100%"
            className="w-1/4 lg:w-1/3"
            preserveAspectRatio="none"
            viewBox="0 0 41 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M 1 6 L 9.298 2 L 15.936 6 L 24.234 2 L 32.117 6 L 40 2"
              stroke="#FF4F16"
              strokeWidth="2"
            />
          </svg>
        </p>
      </div>
      <div className="flex flex-col space-y-12 items-center">
        <div className="flex flex-wrap gap-y-6 items-start w-full xl:w-10/12 mx-auto">
          {blogs.map((blog, index) => (
            <ArticleCard
              key={index}
              title={blog.title}
              blogger={blog.author.name}
              description={blog.description}
              date={blog.publishedAt}
              category={blog.category.title}
              image={blog.mainImage.asset.url}
              slug={blog.slug.current}
              id={blog._id}
              style={style}
            />
          ))}
        </div>
        <div className="text-sm w-fit border border-csdark py-2 px-4 text-center rounded-md font-semibold text-csdark hover:bg-csdark hover:text-white transition-colors duration-500 ease-in-out cursor-pointer">
          Load more D
        </div>
      </div>
    </div>
  );
}
