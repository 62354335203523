import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import { PortableText } from "@portabletext/react";
import urlBuilder from "@sanity/image-url";
import { getImageDimensions } from "@sanity/asset-utils";
import imageClient from "../../../utilities/imageUrlBuilder";
export default function ArticleBody({
  author,
  publishedAt,
  image,
  category,
  title,
  body,
}) {
  // Barebones lazy-loaded image component
  const SampleImageComponent = ({ value }) => {
    const { width, height } = getImageDimensions(value);
    return (
      <img
        src={urlBuilder(imageClient)
          .image(value)
          .width(800)
          .fit("max")
          .auto("format")
          .url()}
        alt={value.alt || " "}
        loading="lazy"
        style={{
          // Avoid jumping around with aspect-ratio CSS property
          aspectRatio: width / height,
        }}
      />
    );
  };

  const myPortableTextComponents = {
    marks: {
      link: ({ children, value }) => {
        const rel = !value.href.startsWith("/")
          ? "noreferrer noopener"
          : undefined;
        return (
          <a
            href={value.href}
            rel={rel}
            className="underline cursor-pointer hover:text-csorange"
          >
            {children}
          </a>
        );
      },
    },

    types: {
      image: SampleImageComponent,
    },

    list: {
      // Ex. 1: customizing common list types
      bullet: ({ children }) => (
        <ul className="list-disc space-y-2 pl-6">{children}</ul>
      ),
      number: ({ children }) => (
        <ol className="list-decimal space-y-2 pl-6">{children}</ol>
      ),
    },
    block: {
      blockquote: ({ children }) => (
        <blockquote className="border-l-4 border-csorange/90 pl-4 italic">
          {children}
        </blockquote>
      ),
    },
  };
  return (
    <div className="container mx-auto h-fit mt-52">
      <div className="flex flex-col lg:w-10/12 mx-auto space-y-12 px-2">
        <div className="flex flex-col">
          <div className="w-full h-full aspect-w-1 aspect-h-1">
            <img
              className="object-cover w-full h-full rounded-md"
              src={`${image}`}
              alt="image"
            />
          </div>
          <div className="flex flex-col text-csdark/75 font-semibold text-sm">
            <p>{author}</p>
            <p>{publishedAt}</p>
            <p>{category}</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row w-full">
          <div className="w-1/12 flex flex-col space-y-3 md:items-center">
            <p className="font-semibold md:text-sm text-csdark md:text-csdark/80">
              Share
            </p>
            <div className="flex md:flex-col space-x-4 md:space-x-0 md:space-y-4 items-center justify-start">
              <a
                href={`#`}
                className="px-2 py-1 border border-csdark rounded-full"
              >
                <i className="fa-brands fa-facebook text-csdark "></i>
              </a>
              <a
                href={`#`}
                className="px-2 py-1 border border-csdark rounded-full"
              >
                <i className="fa-brands fa-linkedin text-csdark "></i>
              </a>
              <a
                href={`#`}
                className="px-2 py-1 border border-csdark rounded-full"
              >
                <i className="fa-brands fa-twitter text-csdark "></i>
              </a>
              <a
                href={`#`}
                className="px-2 py-1 border border-csdark rounded-full"
              >
                <i className="fa-solid fa-envelope text-csdark "></i>
              </a>
            </div>
          </div>
          {/* Body */}
          <div className="flex flex-col space-y-4 w-11/12 h-full">
            <h2 className="text-4xl font-semibold">{title}</h2>
            {/* <BlockContent
              blocks={body}
              projectId="vit43c43"
              dataset="production"
            /> */}
            <PortableText value={body} components={myPortableTextComponents} />
          </div>
        </div>
      </div>
    </div>
  );
}
