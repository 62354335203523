import React from "react";

export default function Footer() {
  return (
    <footer className="bg-csdark w-full px-2 md:px-8 py-4 ">
      <div className="flex justify-between lg:justify-around items-start text-white">
        <div className="flex flex-col items-center space-y-8">
          <div>
            <img src="/logo_white.png" alt="logo" className="h-16" />
          </div>
          <div className="flex space-x-4">
            <a href={`#`}>
              <i className="fa-brands fa-facebook text-white "></i>
            </a>
            <a href={`#`}>
              <i className="fa-brands fa-linkedin text-white "></i>
            </a>
            <a href={`#`}>
              <i className="fa-brands fa-twitter text-white "></i>
            </a>
            <a href={`#`}>
              <i className="fa-solid fa-envelope text-white "></i>
            </a>
          </div>
        </div>
        <div className="hidden md:flex flex-col items-center space-y-4 text-sm ">
          <h2 className="font-bold">Services</h2>
          <div className="flex flex-col space-y-1">
            <p>Mobile App Development</p>
            <p>Mobile App Development</p>
            <p>Mobile App Development</p>
            <p>Mobile App Development</p>
            <p>Mobile App Development</p>
            <p>Mobile App Development</p>
          </div>
        </div>
        <div className="hidden min-[600px]:flex flex-col items-center space-y-4 text-sm ">
          <h2 className="font-bold">Company</h2>
          <div className="flex flex-col space-y-1">
            <p>About</p>
            <p>Terms</p>
            <p>Privacy Policy</p>
            <p>Careers</p>
          </div>
        </div>
        <div className="hidden md:flex flex-col items-center space-y-4 text-sm ">
          <h2 className="font-bold">Partners</h2>
          <div className="flex flex-col space-y-1">
            <p>MTL</p>
            <p>Airtel</p>
            <p>Yeaster</p>
            <p>Fanvil</p>
            <p>Yealink</p>
            <p>TNM</p>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-4 text-sm ">
          <h2 className="font-bold">Contact</h2>
          <div className="flex flex-col space-y-1">
            <p>Area 49, Lilongwe, Malawi</p>
            <p>+265 999 59 00 58</p>
            <p>+265 884 58 99 69</p>
            <p>conceptsolutions@gmail.com</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
