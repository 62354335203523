import React from "react";
import WorkCard from "../../cards/WorkCard";

export default function OurWork() {
  const allWork = [
    {
      title: "Mobile App Development",
      image: "",
      description: `With an increasing number of people using mobile
    applications for almost everything, a good Mobile 
    application is the need of every enterprise. We take in 
    the raw requirements of clients and process out a fully 
    functional application that your target market will love. 
    We integrate all the client requirements and needs into 
    an eye-catching application with fascinating styles, 
    color schemes and an interactive interface to mesmerize 
    the user. Our applications are also tailored to handle 
    easy-read.`,
      workLink: "",
    },
    {
      title: "VoIP AND SIP SOLUTIONS",
      image: "",
      description: `We offer VoIP Solutions from a small organization of less 
      than 50 staff members, to Large organizations of over 
      500 staff members. We boast to be among the best in 
      the industry in Malawi. We have partnered with both 
      TNM, Airtel and MTL that we install pabx and they install 
      SIP lines hence WAIVING LINE RENTAL AND INSTALLATION 
      CHARGES. And how about that!`,
      workLink: "",
    },
    {
      title: "Network Installation",
      image: "",
      description: `We install Local Area Networks (LAN) as well as Wide 
      Area Networks (WAN) for organizations. We also provide 
      24 hour network support services to companies that 
      have no LAN network administrators or an IT 
      department. We offer IT consultancy services to 
      organizations from LAN architecture to business 
      applications to use to maximize production and 
      efficiency.`,
      workLink: "",
    },
    {
      title: "System Automation",
      image: "",
      description: `We also offer automation of various tiring manual 
      systems that you have in place. From HUMAN RESOURCE 
      MANAGEMENT SYSTEMS, VEHICLE TRACKING, HOTEL 
      MANAGEMENT SYSTEMS, HOSPITAL/ CLINIC 
      MANAGEMENT SYSTEMS just to mention a few. If it can be 
      written in code we can do it!`,
      workLink: "",
    },
    {
      title: "Project Management",
      image: "",
      description: `At Massive Dynamics, we also offer project management 
      tools using technology to help ease of the tiring work of 
      managing projects. From systematic data entry to real 
      time analysis of data. We develop offline as well as 
      online applications to assist in project implementation 
      and surveys. Give us a project and see how we handle it!`,
      workLink: "",
    },
    {
      title: "Data Security",
      image: "",
      description: `At massive dynamics we believe security comes first 
      before anything. As we advise all our clients to invest in 
      modern firewalls and antivirus, we also train all our staff 
      that security starts with the code itself, and hence we 
      patch the applications now and again to safeguard its 
      integrity. All our staff have signed NON-DISCLOSURE 
      agreements with our organization, to safe guard clients 
      data as well as our data. We are also willing to sign 
      agreements with clients to safeguard their data.`,
      workLink: "",
    },
  ];
  return (
    <section className="container mx-auto px-4 xl:px-0 flex flex-col gap-y-6">
      <div className="flex lg:mt-8 mx-auto">
        <p className="flex flex-col items-center text-center py-[2px] px-4 font-black text-xl md:text-2xl lg:text-3xl">
          Our Work
          <svg
            height="100%"
            className="w-1/4 lg:w-1/3"
            preserveAspectRatio="none"
            viewBox="0 0 41 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M 1 6 L 9.298 2 L 15.936 6 L 24.234 2 L 32.117 6 L 40 2"
              stroke="#FF4F16"
              strokeWidth="2"
            />
          </svg>
        </p>
      </div>
      <div className="flex flex-col space-y-6 items-center">
        <div className="flex flex-wrap gap-y-6 items-start">
          {allWork.map((work, index) => (
            <WorkCard
              key={index}
              title={work.title}
              description={work.description}
              image={work.image}
              link={work.workLink}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
