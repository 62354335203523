import React from "react";

export default function Separator({ style }) {
  return (
    <div className={`${style}`}>
      <svg
        height="100%"
        preserveAspectRatio="none"
        viewBox="0 0 68 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 3.5 0.333 C 2.027 0.333 0.833 1.527 0.833 3 C 0.833 4.473 2.027 5.667 3.5 5.667 C 4.973 5.667 6.167 4.473 6.167 3 C 6.167 1.527 4.973 0.333 3.5 0.333 Z M 3.5 109.333 C 2.027 109.333 0.833 110.527 0.833 112 C 0.833 113.473 2.027 114.667 3.5 114.667 C 4.973 114.667 6.167 113.473 6.167 112 C 6.167 110.527 4.973 109.333 3.5 109.333 Z M 3 3 L 3 112 L 4 112 L 4 3 L 3 3 Z"
          fill="#FF4F16"
        ></path>
      </svg>
    </div>
  );
}
