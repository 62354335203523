import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ContactUs from "../components/sections/ContactUs";
import SecondaryHero from "../components/sections/SecondaryHero";
import TopOfWeek from "../components/sections/BlogPage/TopOfWeek";
import Others from "../components/sections/BlogPage/Others";
import { motion } from "framer-motion";
import client from "../utilities/sanityClient";

export default function BlogPage({ content, scrollPosition }) {
  const contactUs = {
    style: "border border-csdark text-csdark",
    dotsColor: "bg-csdark",
    buttonStyle:
      "border border-csdark text-csdark hover:bg-csdark hover:text-white transition-colors duration-500 ease-in-out",
  };
  const blogs = [
    {
      image: "",
      title: "How to make your own podcast",
      description: `“Podcasting is a popular and creative way to share your voice, opinions, stories, and expertise with the world. In this article, we will guide you through the steps of creating your own podcast, from planning to recording to publishing.”`,
      date: "10:30 25 March, 2023",
      blogger: "Jane Doe",
      category: "Technology",
      location: "Blantyre, Malawi",
      topOfWeek: true,
    },
    {
      image: "",
      title: "The best places to visit in Malawi",
      description: `“Malawi is a landlocked country in Southern Africa, known for its natural beauty, wildlife, and culture. Whether you are looking for adventure, relaxation, or inspiration, Malawi has something for everyone. Here are some of the best places to visit in Malawi that you should not miss.”`,
      date: "15:00 24 March, 2023",
      blogger: "John Smith",
      category: "Travel",
      location: "Lilongwe, Malawi",
      topOfWeek: false,
    },
    {
      image: "",
      title: "The benefits of meditation and how to start",
      description: `“Meditation is a practice that involves focusing your attention on a single object, thought, or sensation. Meditation can help you reduce stress, improve your mood, enhance your concentration, and more. In this article, we will explain the benefits of meditation and how to start your own practice.”`,
      date: "12:00 23 March, 2023",
      blogger: "Mary Jones",
      category: "Health",
      location: "Zomba, Malawi",
      topOfWeek: false,
    },
    {
      image: "",
      title: "The latest trends in fashion and how to wear them",
      description: `“Fashion is constantly changing and evolving, and it can be hard to keep up with the latest trends. But don't worry, we have got you covered. In this article, we will show you some of the hottest trends in fashion right now and how to wear them with style and confidence.”`,
      date: "18:00 22 March, 2023",
      blogger: "Lisa Brown",
      category: "Fashion",
      location: "Mzuzu, Malawi",
      topOfWeek: false,
    },
  ];

  const [posts, setPosts] = useState(null);

  useEffect(() => {
    // Query data using GROQ
    client
      .fetch(
        `
        *[_type == "post"]{
          _id,
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url   
            },
            alt
          },
          author->{name},
          publishedAt,
          category->{title},  
          description
        }
        `
      )
      .then((posts) => setPosts(posts))
      .catch((error) => console.error(error));
  }, [setPosts]);
  const articleStyle = "bg-cspurple";

  const navbar = {
    home: { style: "text-csorange", stroke: "#FF4F16" },
    who: { style: "text-csorange", stroke: "#FF4F16" },
    what: { style: "text-csorange", stroke: "#FF4F16" },
    work: { style: "text-csorange", stroke: "#FF4F16" },
    blog: {
      style: "text-white bg-csdark lg:mt-8 py-[2px] px-4",
      stroke: "#FFFFFF",
    },
    contactUs: "text-csorange border border-csorange",
    smbg: "bg-white",
    hamburger: "#1E1E1E",
    bg: "bg-white",
    logo: "./logo_purple.png",
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="h-[70vh] md:h-[90vh]">
        <Navbar
          home={navbar.home}
          who={navbar.who}
          what={navbar.what}
          work={navbar.work}
          blog={navbar.blog}
          contactUs={navbar.contactUs}
          smbg={navbar.smbg}
          hamburger={navbar.hamburger}
          scrollPosition={scrollPosition}
          bg={navbar.bg}
          logo={navbar.logo}
        />
        <SecondaryHero
          title={content.title}
          sentence={content.sentence}
          style={content.style}
        />
        {posts && <TopOfWeek post={posts} />}
        {posts && <Others blogs={posts} style={articleStyle} />}
        <ContactUs
          style={contactUs.style}
          dotsColor={contactUs.dotsColor}
          buttonStyle={contactUs.buttonStyle}
        />
        <Footer />
      </div>
    </motion.div>
  );
}
