import React from "react";
import { Link } from "react-router-dom";

export default function TopOfWeek({ post }) {
  console.log(post);
  return (
    <section className="container mx-auto px-4 xl:px-0 ">
      <div className="flex flex-col space-y-6">
        <div className="flex flex-col items-center px-4 lg:mt-4">
          <p className="flex flex-col items-center text-center py-[2px] px-4 font-black text-xl md:text-2xl lg:text-3xl">
            Top of the week
            <svg
              height="100%"
              className="w-1/4 lg:w-1/3"
              preserveAspectRatio="none"
              viewBox="0 0 41 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M 1 6 L 9.298 2 L 15.936 6 L 24.234 2 L 32.117 6 L 40 2"
                stroke="#FF4F16"
                strokeWidth="2"
              />
            </svg>
          </p>
        </div>
        <div className="flex flex-col space-y-4  items-center justify-center md:space-y-0 md:flex-row md:mx-auto md:space-x-8 lg:w-4/5 group">
          <div className="relative h-[250px] w-full md:w-2/5 aspect-w-1 aspect-h-1">
            <img
              className="object-cover w-full h-full rounded-md md:absolute md:-top-4 md:-left-4 md:group-hover:top-0 md:group-hover:-left-0 duration-700"
              src={`${post[0].mainImage.asset.url}`}
              alt=""
            />
            <div className="hidden md:flex absolute blur-sm bg-csdark/60 w-full h-full rounded-md -z-10"></div>
          </div>
          <div className="flex flex-col space-y-4 w-full md:w-3/5">
            <div className="flex flex-col">
              <p className="font-semibold text-sm text-black/75">
                {post[0].author.name}
              </p>
              <p className="font-semibold text-sm text-black/75">
                {post[0].category.title}
              </p>
              <p className="font-semibold text-sm text-black/75">
                {post[0].publishedAt}
              </p>
              {/* <p className="font-semibold text-sm text-black/75">
                {post[0]}
              </p> */}
            </div>
            <h2 className="font-semibold text-base">{post[0].title}</h2>
            <div className="flex">
              <p className="text-sm">{post[0].description}</p>
            </div>
            <Link
              to={`/blog/article/${post[0].slug.current}`}
              className="text-md w-fit border border-csdark py-1 px-2 text-center rounded-md text-csdark hover:bg-csdark hover:text-white transition-colors duration-500 ease-in-out"
            >
              Read More
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
