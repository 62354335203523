import React from "react";

export default function TeamMemberCard({
  name,
  position,
  facebook,
  twitter,
  email,
  linkedIn,
  image,
}) {
  return (
    <div className="w-full min-[500px]:w-1/2 md:w-1/3">
      <div className="flex mx-auto flex-col space-y-2 w-48 lg:w-48">
        <div className="h-48 w-48 lg:h-48 lg:w-48 border-2 border-csorange aspect-w-1 aspect-h-1">
          <img className="object-cover w-full h-full" src="img_1.jpg" alt="" />
        </div>
        <div className="flex justify-between w-full">
          <h3 className="font-medium text-sm ">{name}</h3>
          <div className="flex space-x-2 text-sm">
            <a href={`${facebook}`}>
              <i className="fa-brands fa-facebook text-csdark"></i>
            </a>
            <a href={`${linkedIn}`}>
              <i className="fa-brands fa-linkedin text-csdark"></i>
            </a>
            <a href={`${twitter}`}>
              <i className="fa-brands fa-twitter text-csdark"></i>
            </a>
            <a href={`${email}`}>
              <i className="fa-solid fa-envelope text-csdark"></i>
            </a>
          </div>
        </div>
        <p className="text-csdark/70 text-sm font-normal">{position}</p>
      </div>
    </div>
  );
}
