import React from "react";
import { Link } from "react-router-dom";

export default function ArticleCard({
  title,
  description,
  date,
  blogger,
  category,
  image,
  slug,
  id,
  style,
}) {
  return (
    <div className="w-11/12 min-[600px]:w-1/2 md:w-1/3 flex items-center mx-auto min-[600px]:mx-0">
      <div className="flex flex-col w-11/12 min-[400px]:w-64 md:w-52 lg:w-64 space-y-2 mx-auto">
        <div className="relative h-40 w-full aspect-w-1 aspect-h-1">
          <img
            className="object-cover w-full h-full rounded-md"
            src={`${image}`}
            alt=""
          />
          <div
            className={`absolute h-16 w-16 ${style} rounded-md top-3 right-3 text-center p-2 opacity-95`}
          >
            <p className="font-semibold text-white">
              23 <br /> Mar
            </p>
          </div>
        </div>
        <div className="flex flex-col space-y-0.5 items-start">
          <h2 className="font-semibold text-base">{title}</h2>
          <p className="font-semibold text-sm text-black/75">{category}</p>
          <p className="font-semibold text-sm text-black/75">{blogger}</p>
          <p className="font-semibold text-sm text-black/75">{date}</p>
        </div>
        <div className="flex-inline">
          <p className="parent-container text-sm">{description}</p>
          <Link
            to={`/blog/article/${slug}`}
            className="text-sm font-semibold text-cspurple underline"
          >
            read more
          </Link>
        </div>
      </div>
    </div>
  );
}
