import React, { useEffect, useState } from "react";
import ArticleCard from "../../cards/ArticleCard";
import { Link } from "react-router-dom";
import client from "../../../utilities/sanityClient";

export default function Blog() {
  const blogs = [
    {
      image: "",
      title: "How to make your own podcast",
      description: `“Podcasting is a popular and creative way to share your voice, opinions, stories, and expertise with the world. In this article, we will guide you through the steps of creating your own podcast, from planning to recording to publishing.”`,
      date: "10:30 25 March, 2023",
      blogger: "Jane Doe",
      category: "Technology",
      location: "Blantyre, Malawi",
      topOfWeek: true,
    },
    {
      image: "",
      title: "The best places to visit in Malawi",
      description: `“Malawi is a landlocked country in Southern Africa, known for its natural beauty, wildlife, and culture. Whether you are looking for adventure, relaxation, or inspiration, Malawi has something for everyone. Here are some of the best places to visit in Malawi that you should not miss.”`,
      date: "15:00 24 March, 2023",
      blogger: "John Smith",
      category: "Travel",
      location: "Lilongwe, Malawi",
      topOfWeek: false,
    },
    {
      image: "",
      title: "The benefits of meditation and how to start",
      description: `“Meditation is a practice that involves focusing your attention on a single object, thought, or sensation. Meditation can help you reduce stress, improve your mood, enhance your concentration, and more. In this article, we will explain the benefits of meditation and how to start your own practice.”`,
      date: "12:00 23 March, 2023",
      blogger: "Mary Jones",
      category: "Health",
      location: "Zomba, Malawi",
      topOfWeek: false,
    },
  ];

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // Query data using GROQ
    client
      .fetch(
        `
        *[_type == "post"]{
          _id,
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url   
            },
            alt
          },
          author->{name},
          publishedAt,
          category->{title},  
          description
        }
        `
      )
      .then((posts) => setPosts(posts))
      .catch((error) => console.error(error));
  }, []);

  const articleStyle = "bg-gradient-to-r from-csorange to-csmandarinred";
  return (
    <section className="container mx-auto pb-6 px-4 xl:px-0">
      <div className="flex flex-col space-y-8">
        <div className="flex lg:mt-8 mx-auto">
          <p className="flex flex-col items-center text-center py-[2px] px-4 font-black text-xl md:text-2xl lg:text-3xl">
            Blog
            <svg
              height="100%"
              className="w-1/4 lg:w-1/3"
              preserveAspectRatio="none"
              viewBox="0 0 41 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M 1 6 L 9.298 2 L 15.936 6 L 24.234 2 L 32.117 6 L 40 2"
                stroke="#FF4F16"
                strokeWidth="2"
              />
            </svg>
          </p>
        </div>
        <div className="flex flex-wrap gap-y-6 items-start w-full xl:w-10/12 mx-auto">
          {posts.map((post, index) => (
            <ArticleCard
              key={index}
              title={post.title}
              blogger={post.author.name}
              description={post.description}
              date={post.publishedAt}
              category={post.category.title}
              image={post.mainImage.asset.url}
              slug={post.slug.current}
              id={post._id}
              style={articleStyle}
            />
          ))}
        </div>
        <div className="flex justify-center">
          <Link
            to={"../blog"}
            className="w-fit border border-white py-2 px-4 text-center bg-cspurple rounded-md font-semibold text-white  hover:bg-purple-900 transition-colors duration-300 ease-in-out"
          >
            See More
          </Link>
        </div>
      </div>
    </section>
  );
}
