import { createClient } from "@sanity/client";

// Initialize Sanity client with project ID and dataset name
const client = createClient({
  projectId: "vit43c43",
  dataset: "production",
  useCdn: true, // set to `true` to fetch from edge cache
  apiVersion: "2022-01-12", // use current date (YYYY-MM-DD) to target the latest API version
});

export default client;
