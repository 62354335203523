import React from "react";

export default function PartnerCard() {
  return (
    <div className="w-full min-[400px]:w-1/2 min-[600px]:w-1/3 flex items-center">
      <div className="border border-csorange p-1 w-[170px] h-[170px] mx-auto aspect-w-1 aspect-h-1">
        <img className="object-cover w-full h-full" src="img_2.jpg" alt="" />
      </div>
    </div>
  );
}
