import React from "react";
import Navbar from "../components/Navbar";
import ContactUs from "../components/sections/ContactUs";
import SecondaryHero from "../components/sections/SecondaryHero";
import WorkPageReviews from "../components/sections/WorkPage/WorkPageReviews";
import Footer from "../components/Footer";
import OurWork from "../components/sections/WorkPage/OurWork";
import { motion } from "framer-motion";

export default function WhatPage({ content, scrollPosition }) {
  const contactUs = {
    style: "bg-cspurple text-white",
    dotsColor: "bg-white",
    buttonStyle:
      "border border-white text-white hover:bg-white hover:text-cspurple transition-colors duration-500 ease-in-out",
  };

  const navbar = {
    home: {
      style: "text-white",
      stroke: "#FFFFFF",
    },
    who: { style: "text-white", stroke: "#FFFFFF" },
    what: { style: "text-white", stroke: "#FFFFFF" },
    work: {
      style: "text-white bg-csorange lg:mt-8 py-[2px] px-4",
      stroke: "#FFFFFF",
    },
    blog: {
      style: "text-white",
      stroke: "#FFFFFF",
    },
    contactUs: "text-white border border-white",
    smbg: "bg-cspurple",
    hamburger: "white",
    bg: "bg-cspurple",
    logo: "./logo_white.png",
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="h-[70vh] md:h-[90vh]">
        <Navbar
          home={navbar.home}
          who={navbar.who}
          what={navbar.what}
          work={navbar.work}
          blog={navbar.blog}
          contactUs={navbar.contactUs}
          smbg={navbar.smbg}
          hamburger={navbar.hamburger}
          bg={navbar.bg}
          scrollPosition={scrollPosition}
          logo={navbar.logo}
        />
        <SecondaryHero
          title={content.title}
          sentence={content.sentence}
          style={content.style}
        />
        <OurWork />
        <WorkPageReviews />
        <ContactUs
          style={contactUs.style}
          dotsColor={contactUs.dotsColor}
          buttonStyle={contactUs.buttonStyle}
        />
        <Footer />
      </div>
    </motion.div>
  );
}
