import { useState, useEffect } from "react";

export function useScrollPosition() {
  // Initialize a state variable to store the scroll position
  const [scrollPosition, setScrollPosition] = useState(0);

  // Define a function that updates the scroll position
  const updateScrollPosition = () => {
    // Get the current scroll position of the window
    const currentScrollPosition = window.pageYOffset;
    // Set the state variable with the new scroll position
    setScrollPosition(currentScrollPosition);
  };

  // Use an effect hook to add an event listener for scroll events
  useEffect(() => {
    // Add the event listener when the component mounts
    window.addEventListener("scroll", updateScrollPosition);
    // Remove the event listener when the component unmounts
    return () => window.removeEventListener("scroll", updateScrollPosition);
  }, []);

  // Return the scroll position as the output of the custom hook
  return scrollPosition;
}
