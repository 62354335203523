import React from "react";
import ReviewCard from "../../cards/ReviewCard";

export default function WorkPageReviews() {
  const reviews = [
    {
      name: "Harris Chatuwa",
      image: "",
      review:
        "I hired Concept Solutions to develop a website for my business and I was very impressed with their work. They delivered the project on time, within budget, and with great quality. They were also very responsive and helpful throughout the process. I would highly recommend them to anyone looking for a professional and reliable IT partner.",
    },
    {
      name: "Peter Chatuwa",
      image: "",
      review:
        "Concept Solutions was a pleasure to work with. They fulfilled all their promises and delivered a stunning and functional product. They met all the deadlines, charged me fairly, and fixed any issues promptly. They also listened to my feedback and requests for changes. I got a lot of value and satisfaction from them and I’m glad I hired them.",
    },
    {
      name: "Max Payne",
      image: "",
      review:
        "Concept Solutions was excellent and exceeded my expectations. They did a superb job on my project and went above and beyond what I asked for. They were also quick to communicate and easy to reach. They excelled on every aspect of the project, such as testing, documentation, and user experience. They were great and I would definitely work with them again.",
    },
    {
      name: "David Beckham",
      image: "",
      review:
        "Concept Solutions was excellent and exceeded my expectations. They did a superb job on my project and went above and beyond what I asked for. They were also quick to communicate and easy to reach. They excelled on every aspect of the project, such as testing, documentation, and user experience. They were great and I would definitely work with them again.",
    },
    {
      name: "John Doe",
      image: "",
      review:
        "Concept Solutions was a pleasure to work with. They fulfilled all their promises and delivered a stunning and functional product. They met all the deadlines, charged me fairly, and fixed any issues promptly. They also listened to my feedback and requests for changes. I got a lot of value and satisfaction from them and I’m glad I hired them.",
    },
    {
      name: "John Doe",
      image: "",
      review:
        "Concept Solutions was a pleasure to work with. They fulfilled all their promises and delivered a stunning and functional product. They met all the deadlines, charged me fairly, and fixed any issues promptly. They also listened to my feedback and requests for changes. I got a lot of value and satisfaction from them and I’m glad I hired them.",
    },
  ];
  return (
    <section className="px-4 xl:px-0 mt-12">
      <div className="flex flex-col items-center px-4 lg:mt-4">
        <p className="font-bold text-xl md:text-2xl lg:text-3xl inline-block">
          What People Say About Us
        </p>
        {/* <svg
        height="100%"
        preserveAspectRatio="none"
        viewBox="0 0 41 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 1 6 L 9.298 2 L 15.936 6 L 24.234 2 L 32.117 6 L 40 2"
          stroke="#FF4F16"
          strokeWidth="2"
        />
      </svg> */}
        <p>s</p>
      </div>
      <div className="flex flex-col space-y-12 items-center mt-20">
        <div className="flex flex-wrap gap-y-24 items-start justify-start w-full xl:w-10/12 mx-auto">
          {reviews.map((review, index) => (
            <ReviewCard
              style={"bg-csdark text-white"}
              key={index}
              name={review.name}
              image={review.image}
              review={review.review}
            />
          ))}
        </div>

        <div className="text-sm w-fit border border-csdark py-2 px-4 text-center rounded-md font-semibold text-csdark">
          Load more D
        </div>
      </div>
    </section>
  );
}
