import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useScrollPosition } from "../utilities/scrollPosition";
import Navigation from "./Navigation";

export default function Navbar({
  logo,
  home,
  who,
  what,
  work,
  blog,
  contactUs,
  bg,
  smbg,
  hamburger,
  scrollPosition,
  contactPage,
  landingPage,
  article,
  articleTitle,
}) {
  const threshold = 100;

  const [toggleNav, setToggleNav] = useState(false);

  return (
    <nav
      className={`fixed w-full md:py-1 top-0 z-50 flex flex-col gap-y-2  ${
        contactPage
          ? `${bg} drop-shadow-md`
          : ` ${scrollPosition >= threshold ? `${bg} drop-shadow-md` : ""}`
      }`}
    >
      <div className="px-4 hidden items-center md:flex w-full justify-between text-sm lg:text-base">
        {/* Logo */}
        <Link to={"/"}>
          <img src={`${logo}`} alt="logo" className="h-16" />
        </Link>
        <div className="flex items-center w-2/4 justify-between md:space-x-8 lg:space-x-12 xl:space-x-24">
          <Link to="/">
            <div
              className={`flex flex-col items-center ${home.style} ${
                scrollPosition >= threshold ? `mt-0` : ""
              }`}
            >
              <p className="font-medium inline-block">Home</p>
              <svg
                height="100%"
                preserveAspectRatio="none"
                viewBox="0 0 41 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M 1 6 L 9.298 2 L 15.936 6 L 24.234 2 L 32.117 6 L 40 2"
                  stroke={`${home.stroke}`}
                  strokeWidth="2"
                />
              </svg>
            </div>
          </Link>
          <Link to="../who">
            <div
              className={`flex flex-col items-center ${who.style} transition-spacing duration-500 ease-linear`}
            >
              <p className="font-medium inline-block">Who</p>
              <svg
                height="100%"
                preserveAspectRatio="none"
                viewBox="0 0 41 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M 1 6 L 9.298 2 L 15.936 6 L 24.234 2 L 32.117 6 L 40 2"
                  stroke={`${who.stroke}`}
                  strokeWidth="2"
                />
              </svg>
            </div>
          </Link>
          <Link to="../what">
            <div
              className={`flex flex-col items-center ${what.style} transition-spacing duration-500 ease-linear`}
            >
              <p className="font-medium inline-block">What</p>
              <svg
                height="100%"
                preserveAspectRatio="none"
                viewBox="0 0 41 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M 1 6 L 9.298 2 L 15.936 6 L 24.234 2 L 32.117 6 L 40 2"
                  stroke={`${what.stroke}`}
                  strokeWidth="2"
                />
              </svg>
            </div>
          </Link>
          <Link to="../work">
            <div
              className={`flex flex-col items-center ${
                landingPage && scrollPosition >= 100
                  ? `text-csdark`
                  : `${work.style}`
              } transition-spacing duration-500 ease-linear`}
            >
              <p className="font-medium inline-block">Work</p>
              <svg
                height="100%"
                preserveAspectRatio="none"
                viewBox="0 0 41 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M 1 6 L 9.298 2 L 15.936 6 L 24.234 2 L 32.117 6 L 40 2"
                  stroke={`${
                    landingPage && scrollPosition >= 100
                      ? `#FF4F16`
                      : `${work.stroke}`
                  }`}
                  strokeWidth="2"
                />
              </svg>
            </div>
          </Link>
          <Link to="../blog">
            <div
              className={`flex flex-col items-center ${
                landingPage && scrollPosition >= 100
                  ? `text-csdark`
                  : `${blog.style}`
              } transition-spacing duration-500`}
            >
              <p className="font-medium inline-block">Blog</p>
              <svg
                height="100%"
                preserveAspectRatio="none"
                viewBox="0 0 41 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M 1 6 L 9.298 2 L 15.936 6 L 24.234 2 L 32.117 6 L 40 2"
                  stroke={`${
                    landingPage && scrollPosition >= 100
                      ? `#FF4F16`
                      : `${blog.stroke}`
                  }`}
                  strokeWidth="2"
                />
              </svg>
            </div>
          </Link>
        </div>
        <Link to="../contactUs">
          <div
            className={`w-fit p-2 ${
              landingPage && scrollPosition >= 100
                ? `text-csdark border border-csdark`
                : `${contactUs}`
            }`}
          >
            CONTACT US
          </div>
        </Link>
      </div>
      <div
        className={`flex flex-col w-screen mx-auto md:hidden px-4 py-1 ease-linear duration-300 ${
          toggleNav
            ? `bg-white h-[100vh] pb-10`
            : `${smbg} transition-all h-fit`
        }`}
      >
        <div className="flex justify-between items-center p-2">
          <Link to={"/"}>
            {toggleNav ? (
              <img src={`/logo_purple.png`} alt="logo" className="h-12" />
            ) : (
              <img src={`${logo}`} alt="logo" className="h-12" />
            )}
          </Link>
          {/* Hamburger Icon  */}
          <div className="md:hidden">
            <button
              id="menu-btn"
              className={`block hamburger md:hidden focus:outline-none ${
                toggleNav ? "open" : ""
              }`}
              onClick={() => setToggleNav(!toggleNav)}
            >
              <span
                className="hamburger-top"
                style={{
                  background: `${toggleNav ? `#1E1E1E` : `${hamburger}`}`,
                }}
              ></span>
              <span
                className="hamburger-middle"
                style={{
                  background: `${toggleNav ? `#1E1E1E` : `${hamburger}`}`,
                }}
              ></span>
              <span
                className="hamburger-bottom"
                style={{
                  background: `${toggleNav ? `#1E1E1E` : `${hamburger}`}`,
                }}
              ></span>
            </button>
          </div>
        </div>
        {toggleNav ? (
          <div
            className={`flex flex-col justify-between items-center h-full w-full md:hidden`}
          >
            <div className="flex flex-col justify-center space-y-8 items-center w-full h-full">
              <Link
                to={"/"}
                className="text-center w-full p-2 hover:bg-csdark/30"
              >
                <p className="font-medium text-xl">Home</p>
              </Link>

              <Link
                to={"../who"}
                className="flex flex-col text-center hover:bg-csdark/30 w-full p-2"
              >
                <p className="font-medium text-xl">Who</p>
              </Link>

              <Link
                to={"../what"}
                className="flex flex-col text-center hover:bg-csdark/30 w-full p-2"
              >
                <p className="font-medium text-xl">What</p>
              </Link>

              <Link
                to={"../work"}
                className="flex flex-col text-center hover:bg-csdark/30 w-full p-2"
              >
                <p className="font-medium text-xl">Work</p>
              </Link>

              <Link
                to={"../blog"}
                className="flex flex-col text-center hover:bg-csdark/30 w-full p-2"
              >
                <p className="font-medium text-xl">Blog</p>
              </Link>

              <Link
                to={"../contactUs"}
                className="flex flex-col text-center hover:bg-csdark/30 w-full p-2"
              >
                <p className="font-medium text-xl">Contact Us</p>
              </Link>
            </div>
            <div className="flex flex-col space-y-4 justify-center h-fit w-full items-center">
              <p className="text-csdark text-md font-medium">Get in touch</p>
              <div className="flex space-x-12">
                <a href={`#`}>
                  <i className="fa-brands fa-facebook text-csdark "></i>
                </a>
                <a href={`#`}>
                  <i className="fa-brands fa-linkedin text-csdark "></i>
                </a>
                <a href={`#`}>
                  <i className="fa-brands fa-twitter text-csdark "></i>
                </a>
                <a href={`#`}>
                  <i className="fa-solid fa-envelope text-csdark "></i>
                </a>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {article ? <Navigation title={articleTitle} /> : undefined}
    </nav>
  );
}
