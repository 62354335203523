import React from "react";
import { useMediaQuery } from "react-responsive";

export default function SecondaryHero({ title, sentence, style }) {
  const isSmallScreen = useMediaQuery({ query: "(max-height: 500px)" });
  return (
    <section
      className={`${
        isSmallScreen ? `h-fit pb-6` : `h-full`
      } pt-16 md:-mt-20 ${style}`}
    >
      <div className="flex h-full">
        <div
          className={`flex flex-col items-center ${
            isSmallScreen ? `space-y-14` : `space-y-14 md:space-y-36`
          } mt-28 mx-auto md:mt-56 xl:w-4/5`}
        >
          <h2
            className={`font-bold text-2xl md:text-3xl lg:font-extrabold lg:text-4xl ${title.style}`}
          >
            {title.text}
          </h2>
          <p
            className={`px-2 font-normal md:font-bold text-base lg:font-bold lg:text-2xl text-center ${sentence.style}`}
          >
            {sentence.text}
          </p>
        </div>
      </div>
    </section>
  );
}
