import React from "react";

export default function Navigation({ title }) {
  return (
    <div className="w-full px-6 py-1 border-y-2 border-dashed border-csdark text-sm font-medium">
      <span className="underline">Blog</span> {">"}
      <span className="underline">Articles</span> {">"}
      <span>{title}</span>
    </div>
  );
}
