import React from "react";
import DotSvg from "../../assets/svgs/DotSvg";
import { Link } from "react-router-dom";

export default function ContactUsCard({ style, dotsColor, buttonStyle }) {
  return (
    <div
      className={`relative w-full lg:w-10/12 xl:w-8/12 p-5 flex flex-col items-center gap-10 rounded-md ${style}`}
    >
      <div className="absolute right-4 bottom-4 hidden md:block">
        <DotSvg color={dotsColor} />
      </div>
      <div className="absolute left-4 top-4 hidden md:block">
        <DotSvg color={dotsColor} />
      </div>
      <h2 className="text-lg font-semibold md:text-2xl text-center md:w-2/3">
        Need a reliable team to help you achieve your goals? Look no further.
        We're just a message away.
      </h2>
      <Link
        to={"../contactUs"}
        className={`w-fit py-2 px-4 rounded-md font-semibold cursor-pointer ${buttonStyle}`}
      >
        Contact Us
      </Link>
    </div>
  );
}
