import { motion } from "framer-motion";
import React from "react";
import { useFirstVisit } from "../../utilities/pageVisit";

export default function WorkCard({ title, description, image, link }) {
  const firstVisit = useFirstVisit();

  return (
    <motion.div
      // initial={firstVisit ? { x: "-50vw" } : undefined}
      // whileInView={{ x: 0 }}
      // viewport={{ once: true }}
      // transition={{ duration: 1, ease: "easeIn" }}
      className="flex flex-col md:flex-row md:space-x-2 border-2 border-csdark p-2 mx-auto items-center w-[260px] min-[650px]:w-[49%] md:w-full md:hover:bg-cspurple lg:duration-700 md:hover:text-white group hover:cursor-pointer"
    >
      <h3 className="block md:hidden text-xl font-medium relative z-10 md:-ml-12 text-center">
        {title}
      </h3>
      <div className="flex-none h-40 w-full md:w-36 md:h-36 lg:w-36 lg:h-36 xl:w-40 xl:h-40 aspect-w-1 aspect-h-1">
        <img className="object-cover w-full h-full" src="img_1.jpg" alt="" />
      </div>
      <div className="md:flex justify-between hidden md:space-x-2 order-1 md:order-none">
        <div className="flex-grow flex flex-col md:space-y-2 lg:space-y-8 lg:w-10/12 md:max-h-36">
          <div className="relative">
            <div className="absolute inset-0 bg-white/80 w-[48px] -ml-14 hidden md:block group-hover:hidden duration-500"></div>
            <h3 className="text-xl font-medium relative z-10 md:-ml-12 group-hover:-ml-0 duration-700">
              {title}
            </h3>
          </div>

          <p className="hidden md:block w-full lg:w-10/12 xl:w-8/12 md:text-base md:line-clamp-3">
            {description}
          </p>
        </div>
        {/* <div className="md:self-end w-full h-32 lg:w-2/12 xl:w-1/12 bg-slate-200 p-2 flex flex-col items-center order-3 md:order-none">
          <p className="text-4xl">{">"}</p>
          <p>
            Read <br />
            More
          </p>
        </div> */}
      </div>
      {/* <div className="md:hidden w-full bg-slate-200 p-2 flex items-center">
        <p className="mx-auto">
          Read More <span className="text-base font-medium">{">"}</span>
        </p>
      </div> */}
    </motion.div>
  );
}
