import React from "react";
import PartnerCard from "../cards/PartnerCard";

export default function Partners() {
  const partners = ["#", "#", "#", "#", "#"];
  return (
    <section className="container mx-auto  pb-6 px-4 xl:px-0">
      <div className="flex flex-col space-y-4 md:space-y-8">
        <div className="flex lg:mt-8 mx-auto">
          <p className="flex flex-col items-center text-center py-[2px] px-4 font-black text-xl md:text-2xl lg:text-3xl inline-block">
            Our Partners
            <svg
              height="100%"
              className="w-1/3 md:w-1/4 lg:w-1/3"
              preserveAspectRatio="none"
              viewBox="0 0 41 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M 1 6 L 9.298 2 L 15.936 6 L 24.234 2 L 32.117 6 L 40 2"
                stroke="#FF4F16"
                strokeWidth="2"
              />
            </svg>
          </p>
        </div>
        <div className="flex flex-wrap gap-y-6 items-center w-full xl:w-10/12 mx-auto">
          {partners.map((partner, index) => (
            <PartnerCard image={partner} key={index} />
          ))}
        </div>
      </div>
    </section>
  );
}
