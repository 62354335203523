import React from "react";
import DotSvg from "../assets/svgs/DotSvg";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { motion } from "framer-motion";

export default function ContactUsPage() {
  const navbar = {
    home: {
      style: "text-white",
      stroke: "#FF4F16",
    },
    who: { style: "text-white", stroke: "#FF4F16" },
    what: { style: "text-white", stroke: "#FF4F16" },
    work: {
      style: "text-white",
      stroke: "#FF4F16",
    },
    blog: {
      style: "text-white",
      stroke: "#FF4F16",
    },
    contactUs: "text-white bg-csorange",
    bg: "bg-csdark",
    smbg: "bg-csdark",
    hamburger: "white",
    contactPage: true,
    logo: "./logo_white.png",
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="h-[70vh] md:h-[90vh]">
        <Navbar
          home={navbar.home}
          who={navbar.who}
          what={navbar.what}
          work={navbar.work}
          blog={navbar.blog}
          contactUs={navbar.contactUs}
          bg={navbar.bg}
          smbg={navbar.smbg}
          hamburger={navbar.hamburger}
          contactPage={navbar.contactPage}
          logo={navbar.logo}
        />
        <section className="container mx-auto pt-6">
          <div className="flex flex-col items-center space-y-12">
            <h2 className="font-bold text-xl md:text-2xl lg:text-3xl">
              CONTACT US
            </h2>
            <p className="font-semibold text-2xl text-center">
              Get in Touch with Concept Solutions - Your business Solution
              Partner
            </p>
            <div className="bg-csdark py-6 text-white flex flex-wrap gap-y-8 md:rounded-md justify-around md:w-11/12 lg:w-10/12 xl:w-full">
              <div className="flex flex-col space-y-4 items-center w-1/2 md:w-1/4">
                <p>Icon</p>
                <p className="font-bold">Call Us</p>
                <div className="flex flex-col space-y-2 text-sm">
                  <p>+265 999 59 00 58</p>
                  <p>+265 884 58 99 69</p>
                </div>
              </div>
              <div className="flex flex-col space-y-4 items-center w-1/2 md:w-1/4">
                <p>Icon</p>
                <p className="font-bold">Email Us</p>
                <div className="flex flex-col space-y-2 text-sm">
                  <p>conceptsolutions@gmail.com</p>
                </div>
              </div>
              <div className="flex flex-col space-y-4 items-center w-1/2 md:w-1/4">
                <p>Icon</p>
                <p className="font-bold">Visit Us</p>
                <div className="flex flex-col space-y-2 text-sm">
                  <p>Area 49, Lilongwe, Malawi</p>
                </div>
              </div>
              <div className="flex flex-col space-y-4 items-center w-1/2 md:w-1/4">
                <p>Icon</p>
                <p className="font-bold">Whatsapp Us</p>
                <div className="flex flex-col space-y-2 text-sm">
                  <p>+265 999 59 00 58</p>
                  <p>+265 884 58 99 69</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container mx-auto h-fit pt-6 mb-20">
          <div className="flex flex-col items-center space-y-12">
            <p className="font-semibold text-2xl text-center">
              Get in Touch with Concept Solutions - Your business Solution
              Partner
            </p>
            <div className="relative bg-csdark flex flex-col h-fit w-full md:w-11/12 lg:w-10/12 xl:w-full md:rounded-md">
              <div className="absolute right-6 bottom-6 xl:right-16 xl:bottom-12">
                <DotSvg color={"bg-white hidden md:block"} />
              </div>
              <div className="absolute left-6 top-6 xl:left-16 xl:top-12">
                <DotSvg color={"bg-white hidden md:block"} />
              </div>
              <div className="w-9/12 xl:w-7/12 mx-auto py-4 h-fit flex flex-col justify-between items-center">
                <h2 className="font-medium text-xl text-white text-center">
                  Send Us A Message
                </h2>
                <div className="flex flex-col w-full h-fit justify-between space-y-4 text-sm items-center">
                  <input
                    className="border-b-2 bg-transparent p-2 focus:outline-none text-white border-white w-full"
                    placeholder="Name"
                    type="text"
                  />
                  <input
                    className="border-b-2 bg-transparent p-2 focus:outline-none text-white border-white w-full"
                    placeholder="Email Address"
                    type="text"
                  />
                  <input
                    className="border-b-2 bg-transparent p-2 focus:outline-none text-white border-white w-full"
                    placeholder="Mobile Number"
                    type="text"
                  />
                  <input
                    className="border-b-2 bg-transparent p-2 focus:outline-none text-white border-white w-full"
                    placeholder="Company Name"
                    type="text"
                  />
                  <textarea
                    className="border-b-2 bg-transparent p-2 focus:outline-none text-white border-white w-full"
                    rows={"6"}
                    placeholder="Message"
                  ></textarea>
                  <div className="border border-white px-4 py-2 text-white w-fit rounded-md font-medium">
                    Send Message
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </motion.div>
  );
}
