import React from "react";

export default function GoalVision() {
  return (
    <section className="container mx-auto pb-6 px-4 xl:px-0 xl:w-10/12 ">
      <div className="flex flex-col space-y-12">
        <div className="flex flex-col items-center py-[2px] px-4 lg:mt-8">
          <div className="flex lg:mt-8 mx-auto">
            <p className="flex flex-col items-center text-center py-[2px] px-4 font-black text-xl md:text-2xl lg:text-3xl">
              Our Goal and Vision
              <svg
                height="100%"
                className="w-1/4 lg:w-1/3"
                preserveAspectRatio="none"
                viewBox="0 0 41 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M 1 6 L 9.298 2 L 15.936 6 L 24.234 2 L 32.117 6 L 40 2"
                  stroke="#FF4F16"
                  strokeWidth="2"
                />
              </svg>
            </p>
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <div className="flex space-x-2 items-end">
            <div className="h-20 w-20 md:h-36 md:w-36 aspect-w-1 aspect-h-1">
              <img
                className="object-cover w-full h-full"
                src="goal.svg"
                alt=""
              />
            </div>
            <h3 className="font-medium text-base md:text-xl">Goal</h3>
          </div>
          <p className="">
            Our goal is to deliver software based solutions and products to our
            valuable customers in the most desired way to ensure maximum
            customer satisfaction. With today's cutting-edge technologies, our
            engineers hope to bridge the digital divide by widening our services
            to the communities, providing them with latest products and services
            at a cost they can manage.
          </p>
        </div>
        <div className="flex flex-col space-y-2">
          <div className="flex space-x-2 items-end">
            <div className="h-20 w-20 md:h-36 md:w-36 aspect-w-1 aspect-h-1">
              <img
                className="object-cover w-full h-full"
                src="vision.svg"
                alt=""
              />
            </div>
            <h3 className="font-medium text-base md:text-xl">Vision</h3>
          </div>
          <p>
            We strongly believe that organizations who invest wisely in
            technology, as a general rule, increase their operations, and reduce
            costs. With a stable software applications platform, organizations
            are able to develop unique and efficient business processes. The
            outcome is an organization with lower operational costs, efficient
            work force and healthy profits.
          </p>
        </div>
      </div>
    </section>
  );
}
