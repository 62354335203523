import { BrowserRouter, Route, Routes } from "react-router-dom";
import ArticlePage from "./pages/ArticlePage";
import BlogPage from "./pages/BlogPage";
import ContactUsPage from "./pages/ContactUsPage";
import LandingPage from "./pages/LandingPage";
import WhatPage from "./pages/WhatPage";
import WhoPage from "./pages/WhoPage";
import WorkPage from "./pages/WorkPage";
import { useScrollPosition } from "./utilities/scrollPosition";
import ScrollToTop from "./utilities/scrollToTop";
import { AnimatePresence } from "framer-motion";

function App() {
  const pageContent = {
    blog: {
      title: {
        text: "THE BLOG",
        style: "text-csdark",
      },
      sentence: {
        text: "We are a customer-centric software service provider offering advanced business solutions for small offices, large institutions, and government departments with flexible and efficient voice and data products.",
        style: "text-dark",
      },
      style: "border-b border-csdark",
    },
    work: {
      title: {
        text: "WORK WE'VE DONE?",
        style: "text-white",
      },
      sentence: {
        text: "We are a customer-centric software service provider offering advanced business solutions for small offices, large institutions, and government departments with flexible and efficient voice and data products.",
        style: "text-white",
      },
      style: "bg-cspurple",
    },
    what: {
      title: {
        text: "WHAT WE DO?",
        style: "text-white",
      },
      sentence: {
        text: "We are a customer-centric software service provider offering advanced business solutions for small offices, large institutions, and government departments with flexible and efficient voice and data products.",
        style: "text-white",
      },
      style: "bg-gradient-to-r from-csorange to-csmandarinred",
    },
    who: {
      title: {
        text: "WHO ARE WE?",
        style: "text-csorange",
      },
      sentence: {
        text: "We are a customer-centric software service provider offering advanced business solutions for small offices, large institutions, and government departments with flexible and efficient voice and data products.",
        style: "text-white",
      },
      style: "bg-csdark",
    },
  };
  const scrollPosition = useScrollPosition();

  // const
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route
            path="/"
            element={
              <AnimatePresence>
                <LandingPage scrollPosition={scrollPosition} />{" "}
              </AnimatePresence>
            }
          />
          <Route
            path="blog"
            element={
              <AnimatePresence>
                <BlogPage
                  content={pageContent.blog}
                  scrollPosition={scrollPosition}
                />
              </AnimatePresence>
            }
          />
          <Route
            path="blog/article/:slug"
            element={
              <AnimatePresence>
                <ArticlePage scrollPosition={scrollPosition} />{" "}
              </AnimatePresence>
            }
          />
          <Route
            path="work"
            element={
              <AnimatePresence>
                <WorkPage
                  content={pageContent.work}
                  scrollPosition={scrollPosition}
                />
              </AnimatePresence>
            }
          />

          <Route
            path="contactUs"
            element={
              <AnimatePresence>
                <ContactUsPage />{" "}
              </AnimatePresence>
            }
          />

          <Route
            path="what"
            element={
              <AnimatePresence>
                <WhatPage
                  content={pageContent.what}
                  scrollPosition={scrollPosition}
                />
              </AnimatePresence>
            }
          />

          <Route
            path="who"
            element={
              <AnimatePresence>
                <WhoPage
                  content={pageContent.who}
                  scrollPosition={scrollPosition}
                />
              </AnimatePresence>
            }
          />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
