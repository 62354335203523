import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Blog from "../components/sections/LandingPage/Blog";
import ContactUs from "../components/sections/ContactUs";
import Hero from "../components/sections/LandingPage/Hero";
import Partners from "../components/sections/Partners";
import Reviews from "../components/sections/LandingPage/Reviews";
import Services from "../components/sections/Services";
import Who from "../components/sections/Who";
import { motion } from "framer-motion";

export default function LandingPage({ scrollPosition }) {
  const contactUs = {
    style: "bg-gradient-to-r from-csorange to-csmandarinred text-white",
    dotsColor: "bg-white",
    buttonStyle:
      "border border-white text-white hover:bg-white hover:text-csorange transition-colors duration-500 ease-in-out",
  };
  const navbar = {
    home: {
      style: "text-white bg-csdark lg:mt-8 py-[2px] px-4",
      stroke: "#FF4F16",
    },
    who: { style: "text-csdark", stroke: "#FF4F16" },
    what: { style: "text-csdark", stroke: "#FF4F16" },
    work: { style: "text-white", stroke: "#FFFFFF" },
    blog: {
      style: "text-white",
      stroke: "#FFFFFF",
    },
    contactUs: "text-white border border-white",
    bg: "bg-white",
    smbg: "bg-white",
    hamburger: "#1E1E1E",
    landingPage: true,
    logo: "./logo_purple.png",
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="relative md:h-fit">
        <Navbar
          home={navbar.home}
          who={navbar.who}
          what={navbar.what}
          work={navbar.work}
          blog={navbar.blog}
          contactUs={navbar.contactUs}
          bg={navbar.bg}
          smbg={navbar.smbg}
          hamburger={navbar.hamburger}
          scrollPosition={scrollPosition}
          landingPage={navbar.landingPage}
          logo={navbar.logo}
        />
        <Hero />
      </div>
      <div className="relative">
        <Who />
      </div>
      <div>
        <Services />
        <Reviews />
        <Partners />
        {/* <OurWork /> */}
        <Blog />
        <ContactUs
          style={contactUs.style}
          dotsColor={contactUs.dotsColor}
          buttonStyle={contactUs.buttonStyle}
        />
        <Footer />
      </div>
    </motion.div>
  );
}
