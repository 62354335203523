import { motion } from "framer-motion";
import React from "react";
import { useFirstVisit } from "../../utilities/pageVisit";

export default function ReviewCard({ style, name, image, review }) {
  const firstVisit = useFirstVisit();
  return (
    <motion.div
      initial={firstVisit ? { opacity: 0 } : undefined}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, amount: "all" }}
      transition={{ duration: 1.5, ease: "easeIn" }}
      className="w-11/12 min-[370px]:w-9/12 min-[730px]:w-1/3 flex items-center mx-auto"
    >
      <div
        className={`relative ${style} py-4 px-2 h-fit w-full min-[500px]:w-72 min-[730px]:w-56 lg:w-80 mx-auto md:h-fit rounded-md flex flex-col space-y-6`}
      >
        <div className="absolute -top-12 left-0 right-0 mx-auto h-20 w-20 aspect-w-1 aspect-h-1">
          <img
            className="object-cover w-full h-full rounded-full"
            src="img_1.jpg"
            alt=""
          />
        </div>
        <div className="flex flex-col space-y-3 items-center">
          <p className="font-bold text-sm">{name}</p>
          <p className="font-normal text-sm text-ellipsis">{`"${review}"`}</p>
        </div>
      </div>
    </motion.div>
  );
}
