import React from "react";
import TeamMemberCard from "../../cards/TeamMemberCard";

export default function OurTeam() {
  const teamMembers = [
    {
      name: "Peter Chatuwa",
      position: "CEO and Founder",
      facebook: "#",
      twitter: "#",
      email: "#",
      linkedIn: "#",
      image: "https://randomuser.me/api/portraits/women/32.jpg",
    },
    {
      name: "Harris Chatuwa",
      position: "Designer and developer",
      facebook: "",
      twitter: "",
      email: "",
      linkedIn: "",
      image: "",
    },
    {
      name: "Harris Chatuwa",
      position: "Designer and developer",
      facebook: "",
      twitter: "",
      email: "",
      linkedIn: "",
      image: "",
    },

    {
      name: "Raj Patel",
      position: "Accountant",
      facebook: "#",
      twitter: "#",
      email: "#",
      linkedIn: "#",
      image: "https://randomuser.me/api/portraits/men/12.jpg",
    },
    {
      name: "Maria Gonzalez",
      position: "Sales representative",
      facebook: "#",
      twitter: "#",
      email: "#",
      linkedIn: "#",
      image: "https://randomuser.me/api/portraits/women/76.jpg",
    },
    {
      name: "Kevin Smith",
      position: "Project manager",
      facebook: "#",
      twitter: "#",
      email: "#",
      linkedIn: "#",
      image: "https://randomuser.me/api/portraits/men/45.jpg",
    },
    {
      name: "Anna Lee",
      position: "Customer service agent",
      facebook: "#",
      twitter: "#",
      email: "#",
      linkedIn: "#",
      image: "https://randomuser.me/api/portraits/women/21.jpg",
    },
  ];
  return (
    <section className="container mx-auto pb-6 px-4 xl:px-0">
      <div className="flex flex-col space-y-8">
        <div className="flex lg:mt-8 mx-auto">
          <p className="flex flex-col items-center text-center py-[2px] px-4 font-black text-xl md:text-2xl lg:text-3xl">
            Meet our team of creators, designers, and problem solvers
            <svg
              height="100%"
              className="w-1/4 min-[450px]:w-1/6 lg:w-28"
              preserveAspectRatio="none"
              viewBox="0 0 41 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M 1 6 L 9.298 2 L 15.936 6 L 24.234 2 L 32.117 6 L 40 2"
                stroke="#FF4F16"
                strokeWidth="2"
              />
            </svg>
          </p>
        </div>
        <div className="flex flex-wrap gap-y-6 items-center w-full lg:w-5/6 mx-auto">
          {teamMembers.map((member, index) => (
            <TeamMemberCard
              key={index}
              name={member.name}
              position={member.position}
              facebook={member.facebook}
              twitter={member.twitter}
              email={member.email}
              linkedIn={member.linkedIn}
              image={member.image}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
