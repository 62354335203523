import React from "react";
import DotSvg from "../../../assets/svgs/DotSvg";
import { Link } from "react-router-dom";

export default function Hero() {
  return (
    <section className="container mx-auto h-full pt-28 px-4 xl:px-0 -z-10">
      {/* Background in herosection */}
      <div className="absolute top-0 right-0 hidden md:w-5/12 bg-csdark rounded-bl-md h-full md:flex -z-50">
        <div className="absolute bottom-6 right-2">
          <DotSvg color={"bg-csorange"} />
        </div>
        <div className="absolute bottom-8 -left-36 md:-left-[110px] lg:-left-32 md:bottom-14 lg:bottom-12 xl:bottom-16 md:h-[290px] md:w-[330px] lg:h-[360px] lg:w-[420px] xl:h-[400px] xl:w-[500px] aspect-w-1 aspect-h-1">
          <img
            className="rounded-md object-cover w-full h-full"
            src="img_1.jpg"
            alt=""
          />
        </div>
        <div className="absolute top-64 -left-7 lg:top-48 xl:top-40">
          <DotSvg color={"bg-csorange"} />
        </div>
      </div>
      <div className="relative py-8 w-full md:w-7/12 h-full">
        <div className="flex flex-col space-y-8 md:space-y-16 lg:space-y-24 xl:space-y-28 h-full">
          <h1 className="font-semibold text-xl md:text-2xl lg:text-3xl xl:text-3xl lg:px-3 lg:w-11/12 xl:w-10/12 ">
            Our team at{" "}
            <span className="font-bold text-csorange">Concept Solutions</span>{" "}
            <br className="hidden md:flex" />
            is dedicated to providing reliable and{" "}
            <br className="hidden xl:flex" />
            innovative IT services to support your business's success.
          </h1>
          <div className="relative flex md:hidden">
            <div className="flex h-[200px] rounded-md w-full">
              <img
                className="rounded-md object-cover w-full h-full"
                src="img_1.jpg"
                alt=""
              />
            </div>
            {/* <div className="absolute -top-10 -right-0 z-10">
              <DotSvg />
            </div> */}
          </div>

          <p className="md:w-9/12 lg:w-9/12 font-medium text-xl md:text-2xl ">
            Looking for reliable and innovative IT solutions for your business?
            Click the{" "}
            <span className="inline font-semibold italic">'Contact Us'</span>{" "}
            button to schedule a consultation with Concept Solutions.
          </p>
          <Link
            to={"../contactUs"}
            className="w-fit border border-white py-2 px-4 text-center bg-cspurple rounded-md text-white  hover:bg-purple-900 transition-colors duration-300 ease-in-out"
          >
            CONTACT US
          </Link>
        </div>
      </div>
    </section>
  );
}
