import React from "react";
import DotSvg from "../../assets/svgs/DotSvg";
import { motion } from "framer-motion";
import { useFirstVisit } from "../../utilities/pageVisit";
import { Link } from "react-router-dom";

export default function Who() {
  const firstVisit = useFirstVisit();
  return (
    <section className="container mx-auto pb-12 px-4 xl:px-0">
      <div className="flex flex-col space-y-4">
        <div className="hidden md:flex absolute top-12 right-8 lg:top-16 lg:right-16">
          <DotSvg />
        </div>
        <div className="flex lg:mt-8 mx-auto">
          <motion.p
            initial={firstVisit ? "hidden" : undefined}
            whileInView={"visible"}
            viewport={{ once: true }}
            transition={{ duration: 1, ease: "easeIn" }}
            className="flex flex-col items-center text-center py-[2px] px-4 font-black text-xl md:text-2xl lg:text-3xl"
          >
            Who are we?
            <svg
              height="100%"
              className="w-1/4 lg:w-1/3"
              preserveAspectRatio="none"
              viewBox="0 0 41 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M 1 6 L 9.298 2 L 15.936 6 L 24.234 2 L 32.117 6 L 40 2"
                stroke="#FF4F16"
                strokeWidth="2"
              />
            </svg>
          </motion.p>
        </div>
        <div className="hidden md:flex absolute bottom-6 left-1 lg:bottom-4 xl:bottom-4 lg:left-0 xl:left-20 z-10">
          <DotSvg />
        </div>
        <motion.div
          initial={firstVisit ? { x: "-50vw" } : undefined}
          whileInView={{ x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1, ease: "easeIn" }}
          className="md:flex justify-between items-center space-y-4 md:px-7 md:space-x-2 z-20 lg:px-8 xl:px-0"
        >
          <div className="h-[300px] md:h-[500px] lg:h-[500px] md:w-8/12 xl:w-11/12 w-full aspect-w-1 aspect-h-1">
            <img
              className="rounded-md object-cover w-full h-full"
              src="img_2.jpg"
              alt=""
            />
          </div>
          <div className="flex flex-col space-y-6 lg:space-y-12 md:w-4/6 lg:p-4">
            <p className="text-lg">
              <span className="text-xl font-semibold text-csorange">
                Concept Solutions
              </span>{" "}
              is one of the leading service provider in the area of software
              based business applications. We offer fully advanced tomorrow
              technologies today, We offer private sector solutions, as well as
              multi-faceted government departments solutions. We offer ICT based
              solutions to small offices of up to 20 people, to an institution
              of over 500 people. We are a customer-centric company, and in our
              business we treat our clients as kings and queens. Our products
              and services are flexible, scalable, upgradeable and efficient
              solutions for Voice, Data products.
            </p>

            <Link
              to={"../who"}
              className="w-fit rounded-md border py-2 px-4 text-center bg-cspurple text-white hover:bg-purple-900 transition-colors duration-300 ease-in-out"
            >
              Find out more
            </Link>
          </div>
        </motion.div>
      </div>
    </section>
  );
}
