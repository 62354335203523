import React, { useEffect } from "react";
import Separator from "../../../assets/svgs/Separator";
import ReviewCard from "../../cards/ReviewCard";
import { animate, motion, useMotionValue, useTransform } from "framer-motion";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

export default function Reviews() {
  const reviews = [
    {
      name: "Harris Chatuwa",
      image: "",
      review:
        "I hired Concept Solutions to develop a website for my business and I was very impressed with their work. They delivered the project on time, within budget, and with great quality. They were also very responsive and helpful throughout the process. I would highly recommend them to anyone looking for a professional and reliable IT partner.",
    },
    {
      name: "Peter Chatuwa",
      image: "",
      review:
        "Concept Solutions was a pleasure to work with. They fulfilled all their promises and delivered a stunning and functional product. They met all the deadlines, charged me fairly, and fixed any issues promptly. They also listened to my feedback and requests for changes. I got a lot of value and satisfaction from them and I’m glad I hired them.",
    },
    {
      name: "Max Payne",
      image: "",
      review:
        "Concept Solutions was excellent and exceeded my expectations. They did a superb job on my project and went above and beyond what I asked for. They were also quick to communicate and easy to reach. They excelled on every aspect of the project, such as testing, documentation, and user experience. They were great and I would definitely work with them again.",
    },
  ];
  const count = useMotionValue(0);
  const rounded = useTransform(count, (latest) => Math.round(latest));

  useEffect(() => {
    const controls = animate(count, 34);

    return controls.stop;
  }, []);

  const numbers = Array.from({ length: 36 }, (_, i) => i.toString());

  return (
    <section className="w-full bg-csdark pt-8 pb-12 px-4 xl:px-0">
      <div className="flex flex-col space-y-20">
        <div className="flex lg:mt-8 mx-auto">
          <p className="flex flex-col items-center text-center py-[2px] px-4 font-black text-xl md:text-2xl lg:text-3xl text-white">
            What people say about us
            <svg
              height="100%"
              className="w-1/4 lg:w-1/3"
              preserveAspectRatio="none"
              viewBox="0 0 41 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M 1 6 L 9.298 2 L 15.936 6 L 24.234 2 L 32.117 6 L 40 2"
                stroke="#FFFFFF"
                strokeWidth="2"
              />
            </svg>
          </p>
        </div>
        <div className="flex flex-wrap gap-y-24 md:gap-y-0 items-start w-full xl:w-10/12 mx-auto">
          {reviews.map((review, index) => (
            <ReviewCard
              style={"bg-white"}
              key={index}
              name={review.name}
              image={review.image}
              review={review.review}
            />
          ))}
        </div>
        <div className="flex flex-col space-y-12 items-center">
          <h2 className="font-medium text-xl md:text-2xl lg:text-3xl text-white">
            Numbers
          </h2>
          <VisibilitySensor
            onChange={(isVisible) => {
              console.log(isVisible);
            }}
          >
            {({ isVisible }) => (
              <div className="bg-white border border-csorange rounded-md py-4 flex flex-wrap gap-y-4 md:gap-y-0 w-full lg:w-3/4">
                <div className="flex flex-col w-1/2 md:w-1/4 items-center">
                  <h1 className="flex text-xl md:text-4xl text-csorange font-semibold">
                    {isVisible ? (
                      <CountUp start={0} redraw={false} end={36} duration="3" />
                    ) : null}

                    <div className="font-thin">+</div>
                  </h1>
                  <p className="font-normal md:font-semibold">Clients</p>
                </div>
                <Separator style={"hidden md:block"} />
                <div className="flex flex-col w-1/2 md:w-1/4 items-center">
                  <h1 className="flex text-xl md:text-4xl text-csorange font-semibold">
                    {isVisible ? (
                      <CountUp start={0} redraw={false} end={5} duration="3" />
                    ) : null}
                    <div className="font-thin">+</div>
                  </h1>
                  <p className="font-normal md:font-semibold">
                    Years in Business
                  </p>
                </div>
                <Separator style={"hidden md:block"} />
                <div className="flex flex-col w-1/2 md:w-1/4 items-center">
                  <h1 className="flex text-xl md:text-4xl text-csorange font-semibold">
                    {isVisible ? (
                      <CountUp start={0} redraw={false} end={8} duration="3" />
                    ) : null}
                    <div className="font-thin">+</div>
                  </h1>
                  <p className="font-normal md:font-semibold">Team members</p>
                </div>
                <Separator style={"hidden md:block"} />
                <div className="flex flex-col w-1/2 md:w-1/4 items-center">
                  <h1 className="flex text-xl md:text-4xl text-csorange font-semibold">
                    {isVisible ? (
                      <CountUp start={0} redraw={false} end={24} duration="3" />
                    ) : null}
                    <div className="font-thin">+</div>
                  </h1>
                  <p className="font-normal md:font-semibold">Customers</p>
                </div>
              </div>
            )}
          </VisibilitySensor>
        </div>
      </div>
    </section>
  );
}
