import React from "react";

export default function DotSvg({ color }) {
  function createDots(color) {
    return [1, 2, 3, 4].map((n) => (
      <div
        key={n}
        className={`rounded-full w-[6px] h-[6px] lg:w-[7px] lg:h-[7px] ${color}`}
      />
    ));
  }
  return (
    <div className="flex flex-col space-y-2 lg:space-y-3">
      <div className="flex pt-0 pl-0 w-fit md:gap-[10px] lg:gap-[12px]">
        {createDots(color)}
      </div>
      <div className="flex pt-0 pl-0 w-fit md:gap-[10px] lg:gap-[12px]">
        {createDots(color)}
      </div>
      <div className="flex pt-0 pl-0 w-fit md:gap-[10px] lg:gap-[12px]">
        {createDots(color)}
      </div>
      <div className="flex pt-0 pl-0 w-fit md:gap-[10px] lg:gap-[12px]">
        {createDots(color)}
      </div>
    </div>
  );
}
