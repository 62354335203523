import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Navigation from "../components/Navigation";
import Others from "../components/sections/BlogPage/Others";
import ArticleBody from "../components/sections/ArticlePage/ArticleBody";
import { motion } from "framer-motion";
import { useParams, withRouter } from "react-router-dom";
import client from "../utilities/sanityClient";

export default function ArticlePage({ scrollPosition }) {
  const { slug } = useParams();

  const [post, setPost] = useState(null);
  const [otherPosts, setOtherPosts] = useState(null);

  useEffect(() => {
    // Query data using GROQ
    client
      .fetch(
        `
        *[_type == "post" && slug.current == '${slug}']{
          _id,
          title,
          mainImage{
            asset->{
              _id,
              url   
            },
            alt
          },
          author->{name},
          publishedAt,
          category->{title},  
          description,
          body
        }
        `
      )
      .then((post) => {
        setPost(post[0]);
      })
      .catch((error) => console.error(error));
  }, [slug]);
  useEffect(() => {
    // Query data using GROQ
    if (post == null) {
      console.log("is null");
    } else {
      console.log("is no longer null");
      client
        .fetch(
          `
        *[_type == "post" && category->title =='${post.category.title}' && _id != '${post._id}']{
          _id,
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          author->{name},
          publishedAt,
          category->{title},
          description,
          body
        }
        `
        )
        .then((posts) => setOtherPosts(posts))
        .catch((error) => console.error(error));
    }
  }, [post]);

  const navbar = {
    home: { style: "text-csorange", stroke: "#FF4F16" },
    who: { style: "text-csorange", stroke: "#FF4F16" },
    what: { style: "text-csorange", stroke: "#FF4F16" },
    work: { style: "text-csorange", stroke: "#FF4F16" },
    blog: {
      style: "text-white bg-csdark py-[2px] px-4",
      stroke: "#FFFFFF",
    },
    contactUs: "text-csorange border border-csorange",
    smbg: "bg-white",
    hamburger: "#1E1E1E",
    bg: "bg-white",
    logo: "/logo_purple.png",
    article: {
      status: true,
    },
  };

  const articleStyle = "bg-cspurple";
  if (post == null) return null;
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="h-[70vh] md:h-[90vh]">
        <Navbar
          home={navbar.home}
          who={navbar.who}
          what={navbar.what}
          work={navbar.work}
          blog={navbar.blog}
          contactUs={navbar.contactUs}
          smbg={navbar.smbg}
          hamburger={navbar.hamburger}
          scrollPosition={scrollPosition}
          bg={navbar.bg}
          logo={navbar.logo}
          article={navbar.article.status}
          articleTitle={post.title}
        />
        <ArticleBody
          title={post.title}
          author={post.author.name}
          publishedAt={post.publishedAt}
          category={post.category.title}
          image={post.mainImage.asset.url}
          body={post.body}
        />
        {otherPosts ? (
          <Others blogs={otherPosts} style={articleStyle} />
        ) : undefined}

        <Footer />
      </div>
    </motion.div>
  );
}
